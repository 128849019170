<template>
    <b-modal
        id="saveCustomerModal"
        :title="value && value.customer != null ? 'Editar Dados do cliente' : 'Salvar Contato do Cliente'"
        centered
        size="lg"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        @show="onShow"
    >
        <loading v-if="loading" />

        <validation-observer ref="saveCustomerForm" v-if="form.data.length > 0 && !this.loading" class="scrollable">
            <div v-if="$props.value.customer == null" class="row mb-3">
                <div class="col-6">
                    <select v-model="form.form_id" class="form-control">
                        <option :value="null">Selecionar formulário</option>
                        <option v-if="formsLoading">Carregando...</option>
                        <option
                            v-for="(form, i) in forms"
                            :value="form._id"
                            :key="`form-option-${i}`">
                            {{ form.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div v-for="(row, rowId) in form.data" :key="`customer-form-row-${rowId}`">
                <div class="row mb-3" v-if="row.constructor === Array">
                    <validation-provider
                        #default="{ errors }"
                        :rules="{ required: col.required, email: row.type === 'email' }"
                        v-for="(col, colId) in row"
                        class="col d-flex"
                        :key="`customer-form-row-${rowId}-col-${colId}`"
                    >
                        <input 
                            v-if="col.type === 'name' && nameLoading" 
                            type="text" 
                            class="form-control m-0 flex-grow-1"
                            placeholder="Carregando..."
                            readonly
                        >
                        <input
                            v-else
                            type="text"
                            class="form-control m-0 flex-grow-1"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :placeholder="col.name"
                            v-model="col.value"
                            v-mask="masker(col.type)"
                            :masked="false"
                            :readonly="col.type === 'main_phone'"
                        >
                        <button
                            class="ml-2 btn btn-outline-primary"
                            v-b-modal.migratePhoneModal
                            v-if="value.customer != null && String(col.value).replaceAll(/[^0-9]/g, '') === value.phone"
                        >
                            Migrar
                        </button>
                    </validation-provider>
                </div>

                <div v-else class="row mb-3">
                    <validation-provider
                        #default="{ errors }"
                        :rules="{ required: row.required }"
                        class="col d-flex"
                    >
                        <input 
                            v-if="row.type === 'name' && nameLoading" 
                            placeholder="Carregando..."
                            readonly
                        >
                        <input
                            v-else
                            type="text"
                            class="form-control m-0 flex-grow-1"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :placeholder="row.name"
                            v-model="row.value"
                            v-mask="masker(row.type)"
                            :readonly="row.type === 'main_phone'"
                        >
                        <button
                            class="ml-2 btn btn-outline-primary"
                            v-if="value.customer != null && String(row.value).replaceAll(/[^0-9]/g, '') === value.phone"
                        >
                            Migrar
                        </button>
                    </validation-provider>
                </div>
            </div>

            <div class="d-flex justify-content-start align-items-center mt-3">
                <label class="mr-1 text-black m-0">Score</label>
                <b-form-rating
                    class="text-center shadow-none"
                    no-border
                    inline
                    v-model="form.score"
                ></b-form-rating>
            </div>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button :disabled="loading" @click="clean(cancel)" class="btn btn-outline-primary px-5 mr-2">
                Cancelar
            </button>
            <button :disabled="loading" @click="() => saveCustomer(ok)" class="btn btn-primary px-5">
                Salvar
            </button>
        </template>
        <migrate-phone-modal v-if="value.customer != null" v-model="value" value=""/>
    </b-modal>
</template>

<script>
import { BModal, BFormRating } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from "@/views/components/Loading.vue";
import { required } from "@validations";
import api from "@/api";
import { mask } from 'vue-the-mask';
import MigratePhoneModal from "./MigratePhoneModal.vue";

export default {
    components: {
        BModal,
        BFormRating,
        ValidationProvider,
        ValidationObserver,
        Loading,
        MigratePhoneModal
    },
    props: {
        value: {
            type: Object,
            required: true
        }
    },
    watch: {
        value: {
            handler(newValue, oldValue) {
                const score = { score: newValue.customer ? newValue.customer.score : null };
                Object.assign(this.form, score);
                if ((newValue && oldValue && newValue.data != oldValue.data) || (newValue && oldValue && newValue.form_id != oldValue.form_id) || ((!newValue || !oldValue) && newValue != oldValue))
                    this.getForm()
            },
            deep: true,
            immediate: true
        },
        "value.customer.data": {
            handler() {
                this.getForm()
            },
            deep: true,
        },
        'form.form_id'(newValue, oldValue) {
            if (newValue != oldValue)
                this.getForm();
        }
    },
    emits: ['input'],
    data() {
        return {
            required,
            form: {
                form_id: null,
                contact_id: this.$props.value != null ? this.$props.value._id : null,
                phone_id: this.$props.value != null ? this.$props.value.phone_id : null,
                data: [],
                score: null
            },
            forms: [],
            loading: false,
            formsLoading: false,
            nameLoading: false,
            contactName: ''
        };
    },
    directives: {
        mask: (el, binding) => {
            if (!binding.value) return;
                mask(el, binding);
        }
    },
    methods: {
        onShow() {
            if(this.forms.length == 0)
                this.getForms();

            const value = this.$props.value;
            if (value != null && value.name != null && value.phone != null && value.name != value.phone)
                this.contactName = value.name;
        },
        getForms() {
            this.formsLoading = true;

            this.$store.dispatch('form/index', {
                auth: this.$store.getters["user/getAuth"]
            }).finally(() => {
                this.forms = this.$store.getters["form/get"];
                this.formsLoading = false
            })
        },
        masker(type) {
            const types = {
                main_phone: ['+## (##) #####-####', '+## (##) ####-####'],
                phone: ['+## (##) #####-####', '+## (##) ####-####'],
                date: '##/##/####',
                money: '###.###.###.###.###.###.###.###.###,##',
                number: '#'.repeat(255),
            }

            return type in types ? types[type] : null;
        },
        async getForm() {
            if(this.$props.value == null) 
                return;
            this.loading = true;
            if(this.$props.value.customer == null && this.contactName == '')
                await this.getContactName()
            const customer = this.$props.value.customer;
            const formId = customer != null ? customer.form_id : this.form.form_id;

            api.get('/forms/' + (formId == null ? 'default' : formId))
            .then(response => {
                const { type, message, body } = response.data;

                if(type === 'success') {
                    this.form.form_id = body._id;
                    this.form.data = body.fields;

                    if(customer != null) {
                        this.form.data.forEach((row, rowI) => {
                            if(row.constructor === Array) {
                                return row.forEach((col, colI) => {
                                    if(!(col.name in customer.data)) return;
                                    Object.assign(this.form.data[rowI][colI], { value: customer.data[col.name ]});
                                })
                            }

                            if(!(row.name in customer.data)) return;
                            Object.assign(this.form.data[rowI], { value: customer.data[row.name ]});
                        });
                    } else {
                        this.form.data.forEach((row, rowI) => {
                            if(row.constructor === Array) {
                                return row.forEach((col, colI) => {
                                    if(!(col.type === 'main_phone') && !(col.type === 'name')) return;
                                    Object.assign(this.form.data[rowI][colI], { value: (col.type === 'main_phone' ? this.$props.value.phone : this.contactName) });
                                })
                            }

                            if(!(row.type === 'main_phone') && !(row.type === 'name')) return;
                            Object.assign(this.form.data[rowI], { value: (row.type === 'main_phone' ? this.$props.value.phone : this.contactName) });
                        });
                    }
                    return;
                }

                this.$bvToast.toast(message, {title: 'Clientes', variant: 'danger'});
            })
            .catch(error => {
                let message = 'Não foi possível salvar cliente.'
                if(error.response) {
                    message = error.response.data.message;
                }

                this.$bvToast.toast(message, {title: 'Clientes', variant: 'danger'});
            })
            .finally(() => this.loading = false);
        },
        getContactName() {
            this.nameLoading = true
            const phone = this.$props.value.phone
            
            return api.get(`/chats/${phone}/contact-name`)
            .then(response => {
                const { type, message, body } = response.data

                if(type === 'success') {
                    return this.contactName = body.name
                }

                throw message
            })
            .catch(error => {
                let message = 'Não foi possível retornar o nome do contato.'
                if(error.response) {
                    message = error.response.data.message
                }
                console.log(`saveCustomer: ${message}`);
            })
            .finally(() => this.nameLoading = false);
        },
        saveCustomer(closeHandle) {
            this.$refs.saveCustomerForm.validate().then(success => {
                if(!success) return;

                this.loading = true;

                const customer_id = this.$props.value.customer != null ? this.$props.value.customer._id : null;

                api({
                    method: customer_id != null ? 'PUT' : 'POST',
                    url: customer_id != null ? `/customers/${customer_id}` : '/customers',
                    data: this.form
                })
                .then(response => {
                    const { type, message, body } = response.data;

                    if(type === 'success') {
                        this.$bvToast.toast(message, {title: 'Clientes', variant: 'success'});
                        const contact = Object.assign({}, this.$props.value);
                        this.$emit('input', { ...contact, customer: body });
                        return closeHandle()
                    }

                    throw message;
                })
                .catch(error => {
                    let message = 'Não foi possível salvar cliente.'
                    if(error.response) {
                        message = error.response.data.message;
                    }
                    if(typeof error == 'string') {
                        message = error
                    }
                    console.log(`saveCustomer: ${error}`);
                    this.$bvToast.toast(message, {title: 'Clientes', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        },
        clean(closeHandle) {
            this.form = {
                form_id: null,
                contact_id: this.$props.value != null ? this.$props.value._id : null,
                phone_id: this.$props.value != null ? this.$props.value.phone_id : null,
                data: [],
                score: null
            }
            this.$refs.saveCustomerForm.reset();
            this.forms = [];
            closeHandle();
        }
    },
}
</script>

<style lang="scss" scoped>
.scrollable {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
