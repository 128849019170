<template>
	<b-sidebar id="quickAnswersSidebar" lazy no-close-on-esc right shadow @shown="onShow">
		<loading v-if="this.loading.answers" />
		<template v-slot:header="{ hide }">
			<div class="d-flex align-items-center py-4 px-3">
				<font-awesome-icon
					class="mr-5"
					icon="fa-solid fa-chevron-left"
					size="sm"
					@click="hide"
				/>
				<h5 class="m-0 text-blue">Respostas Rápidas</h5>
			</div>
		</template>
		<div class="px-0 py-2 d-flex flex-column h-100 overflow-hidden">
			<div class="mb-3 mx-3">
				<div class="input-group mb-4 w-auto">
					<span class="input-group-text">
						<font-awesome-icon icon="fa-solid fa-magnifying-glass" />
					</span>
					<input
						v-model="filters.search"
						:readonly="this.loading.answers"
						aria-label="Pesquisar"
						class="form-control"
						placeholder="Buscar uma resposta"
						type="text"
					/>
				</div>

				<multiselect
					:options="subjects.map((e) => ({ name: e.name, key: e._id }))"
					label="name"
					:showLabels="false"
					track-by="key"
					v-model="filters.subject_id"
					:placeholder="loading.subjects ? 'Carregando...' : 'Assunto'"
				>
					<template v-slot:noResult>Nenhum assunto encontrado.</template>
					<template v-slot:noOptions>Nenhum assunto encontrado.</template>
				</multiselect>

				<multiselect
					v-model="filters.campaign_id"
					:options="campaigns.map((e) => ({ name: e.name, key: e._id }))"
					label="name"
					:showLabels="false"
					:placeholder="loading.campaigns ? 'Carregando...' : 'Campanha'"
					class="mt-2"
					track-by="key"
				>
					<template v-slot:noResult>Nenhuma campanha encontrada.</template>
					<template v-slot:noOptions>Nenhuma campanha encontrada.</template>
				</multiselect>
			</div>

			<div class="d-flex flex-column flex-grow-1 overflow-auto px-3" @scroll="onScroll">
				<div v-if="answers.length > 0 || this.loading.pagination">
					<div class="accordion" role="tablist">
						<div v-for="(answer, i) in answers" :key="`answer-${i}`" class="mb-4">
							<div
								style="box-shadow: none !important"
								class="card mb-1 rounded bg-white border border-overlay"
							>
								<div
									class="card-header px-1 py-1 d-flex justify-content-between w-100"
									role="tab"
								>
									<span class="text-primary text-left mx-2">
										{{ answer.name }}
									</span>
								</div>
								<div
									class="card-body p-2 shadow-none d-flex flex-column align-items-start"
								>
									<a
										role="button"
										class="text-reset w-100"
										@click="
											() =>
												setAnswers({
													...answer,
													root_text: setTagsMessage(
														answer.layers['1.1'].text
													)
												})
										"
									>
										<div
											style="box-shadow: none !important"
											class="card rounded bg-white w-100"
											role="button"
										>
											<div
												class="card-header px-2 py-1 d-flex justify-content-between w-100 bg-primary text-white"
												role="tab"
											>
												Nível 1
											</div>
											<div class="card-body bg-clear-blue p-2">
												<div
													v-if="answer.layers['1.1'].neddles"
													class="d-flex flex-wrap align-items-center justify-start"
												>
													<span
														v-for="(neddle, i) in answer.layers['1.1']
															.neddles"
														class="badge bg-primary mr-1 mb-1"
														style="
															white-space: normal;
															text-align: left;
														"
														:key="`${answer.id}-${i}`"
													>
														{{ neddle }}
													</span>
												</div>
												<p
													class="m-0 text-justify"
													v-html="
														setTagsMessage(answer.layers['1.1'].text)
													"
												></p>
											</div>
										</div>
									</a>

									<b-collapse
										v-if="Object.keys(answer.layers).length > 1"
										:id="`collapse-${answer._id}`"
										class="w-100"
									>
										<div class="d-flex flex-column">
											<a
												v-for="layer in Object.keys(answer.layers).slice(1)"
												:key="`${answer.id}-${layer}`"
												role="button"
												class="text-reset w-100 mt-2"
												@click="
													() =>
														setAnswers({
															...answer,
															root_text: setTagsMessage(
																answer.layers[layer].text
															)
														})
												"
											>
												<div
													style="box-shadow: none !important"
													class="card rounded bg-white w-100"
													role="button"
												>
													<div
														class="card-header px-2 py-1 d-flex justify-content-between w-100 bg-primary text-white"
														role="tab"
													>
														Nível {{ layer }}
													</div>
													<div class="card-body bg-clear-blue p-2">
														<div
															v-if="answer.layers[layer].neddles"
															class="d-flex flex-wrap align-items-center justify-start"
														>
															<span
																v-for="(neddle, i) in answer.layers[
																	layer
																].neddles"
																class="badge bg-primary mr-1 mb-1"
																style="
																	white-space: normal;
																	text-align: left;
																"
																:key="`${answer.id}-${layer}-${i}`"
															>
																{{ neddle }}
															</span>
														</div>
														<p
															class="m-0 text-justify"
															v-html="
																setTagsMessage(
																	answer.layers[layer].text
																)
															"
														></p>
													</div>
												</div>
											</a>
										</div>
									</b-collapse>

									<font-awesome-icon
										v-if="Object.keys(answer.layers).length > 1"
										icon="fas fa-ellipsis"
										color="grey"
										class="px-1 mt-2"
										v-b-toggle="`collapse-${answer._id}`"
									/>
								</div>
							</div>
						</div>
					</div>
					<span v-if="this.loading.pagination" class="spinner-border"></span>
				</div>
				<p v-else class="m-0 text-center">Nenhuma resposta encontrado</p>
			</div>
		</div>
	</b-sidebar>
</template>

<script>
import { BSidebar, BCollapse } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";
import Multiselect from "vue-multiselect";
import { setTagsMessage } from "@/api/functions";

export default {
	components: {
		BSidebar,
		Loading,
		Multiselect,
		BCollapse
	},
	props: {
		contactId: {
			type: String,
			default: null
		}
	},
	data() {
		return {
			answers: [],
			pagination: null,
			campaigns: [],
			subjects: [],
			filters: {
				search: null,
				subject_id: null,
				campaign_id: null
			},
			filterTimeout: null,
			loading: {
				campaigns: false,
				subjects: false,
				answers: false,
				pagination: false
			}
		};
	},
	watch: {
		filters: {
			handler() {
				clearTimeout(this.filterTimeout);
				this.filterTimeout = setTimeout(() => this.getAnswers(), 700);
			},
			deep: true
		}
	},
	methods: {
		setTagsMessage,
		onShow() {
			this.filters = {
				search: null,
				subject_id: null,
				campaign_id: null
			};

			if (this.answers.length == 0) this.getAnswers(1, true);

			if (this.campaigns.length == 0) this.getCampaigns();

			if (this.subjects.length == 0) this.getSubjects();
		},
		getSubjects() {
			this.loading.subjects = true;

			const params = { all: 1, with_answer: 1 };
			api.get("/answers/subjects", { params })
				.then((response) => {
					const { type, body } = response.data;

					if (type == "success") return (this.subjects = body);

					throw response.data;
				})
				.catch(() => {
					this.subjects = [];
				})
				.finally(() => (this.loading.subjects = false));
		},
		getCampaigns() {
			this.loading.campaigns = true;

			const params = { all: 1, with_answer: 1, only: ["_id", "name"] };
			api.get("/campaigns", { params })
				.then((response) => {
					const { type, body } = response.data;

					if (type == "success") return (this.campaigns = body);

					throw response.data;
				})
				.catch(() => {
					this.campaigns = [];
				})
				.finally(() => (this.loading.campaigns = false));
		},
		getAnswers(page = 1, loading = false) {
			if (this.pagination != null && page > this.pagination.last_page) return;

			this.loading.answers = loading;
			this.loading.pagination = true;

			const params = {
				search: this.filters.search,
				subject_id: this.filters.subject_id ? this.filters.subject_id.key : null,
				campaign_id: this.filters.campaign_id ? this.filters.campaign_id.key : null,
				with_campaign_answer: 1,
				contact_id: this.contactId,
				page
			};

			api.get("/answers", { params })
				.then((response) => {
					const { type, body, misc } = response.data;

					if (type != "success") return (this.answers = []);

                    if (page == 1) {
                        this.answers = body
                    } else {
                        this.answers.push(...body);
                    }
					this.pagination = misc;
				})
				.catch(() => (this.answers = []))
				.finally(() => {
					this.loading.answers = false;
					this.loading.pagination = false;
				});
		},
		onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
			if (
				scrollTop + clientHeight >= scrollHeight &&
				!this.loading.answers &&
				!this.loading.pagination
			)
				this.getAnswers(this.pagination.current_page + 1);
		},
		setAnswers(answer) {
			this.$root.$emit("quick-answer", answer);
		}
	}
};
</script>
