<template>
    <b-modal
        id="saveMessageToCustomerModal"
        title="Salvar Mensagem"
        centered
        size="md"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        v-bind="$attrs"
        @show="getFields"
        @hide="() => $emit('input', null)"
    >
        <loading v-if="loading" />

        <validation-observer ref="saveMessageToCustomerForm">
            <form>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                        <label for="add-group-name">Campo</label>
                        <select
                            v-model="field.name"
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            class="form-control"
                            id="add-group-name"
                        >
                            <option v-if="fields.length === 0" :value="null">Nenhum resultado encontrado</option>
                            <option v-else :value="null" hidden>Selecione um campo</option>
                            <option v-for="(name, index) in fields" :value="name" :key="index">{{ name }}</option>
                        </select>
                    </validation-provider>
                </div>
                <div class="form-group">
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                    >
                        <label for="add-group-name">Mensagem</label>
                        <textarea 
                            :class="`form-control ${errors.length > 0 && 'is-invalid'}`"
                            v-model="field.value"
                            rows="5"
                        ></textarea>
                    </validation-provider>
                </div>
            </form>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button :disabled="loading" @click="cancel" class="btn btn-outline-primary px-5 mr-2">
                Cancelar
            </button>
            <button :disabled="loading" @click="() => submit(ok)" class="btn btn-primary px-5">
                Salvar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";

export default {
    components: {
        BModal,
        ValidationProvider,
        ValidationObserver,
        Loading
    },
    props: {
        value: {
            type: String
        },
        customer: {
            type: Object
        },
    },
    emits: ['input'],
    data() {
        return {
            fields: [],
            field: {
                name: null,
                value: null
            },
            loading: false,
        };
    },
    watch: {
        "$props.value"(val) {
            this.field.value = val;
        }
    },
    methods: {
        getFields() {
            if (this.fields.length > 0)
                return;

            this.loading = true;
            api.get('/forms/fields', {
                params: {
                    form_id: this.customer.form_id,
                    block_main_fields: 1
                }
            })
            .then(res => {
                this.fields = res.data.body;
            })
            .finally(() => this.loading = false)
        },
        submit(closeHandler) {
            this.$refs.saveMessageToCustomerForm.validate()
            .then(success => {
                if(!success) return;
                
                let payload = {};
                payload['Telefone'] = this.$props.customer.phone;
                payload[this.field.name] = String(this.field.value).trim();
                
                this.loading = true;
                api.put(`/customers/${this.customer._id}`, { 
                    data: payload, 
                    phone_id: this.customer.phone_id,
                    form_id: this.customer.form_id ?? null 
                })
                .then(res => {
                    const { type, message, body } = res.data;
                    
                    if (type != 'success')
                        throw message;
                    
                    this.$root.$bvToast.toast("Mensagem salva com sucesso.", {title: 'Clientes', variant: 'success'});
                    this.field.name = null;
                    this.$emit('update', body);
                    closeHandler();
                })
                .catch(() => {
                    this.$bvToast.toast("Não foi possível salvar mensagem.", {title: 'Clientes', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.scrollable {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
