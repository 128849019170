export const filtersModel = {
    search: null,
    score: null,
    tags: [],
    chatStatus: [],
    marks: [],
    dates: [],
    phones: [],
    campaigns: [],
    filtering: false,
    status: [],
}

export const orderModel = {
    order_by: null,
    order_as: null,
    order_with: null
}

export const chatTypes = [
    {
        id: 'finished',
        label: "Atendimento Finalizado",
        icon: "fa-check",
        color: "light-blue",
    },
    {
        id: 'active',
        label: "Manter Conversa Ativa",
        icon: "fa-comments",
        color: "primary",
    },
    {
        id: 'pending',
        label: "Conversa Pendente",
        icon: "fa-rotate-right",
        color: "orange",
    },
    {
        id: 'supervisor',
        label: "Enviar Para o Supervisor",
        icon: "fa-circle-info",
        color: "dark-orange",
    },
];

export const exampleContacts = [
    {
        id: 1,
        fullName: "Felecia Rower",
        role: "Frontend Developer",
        about: "Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing",
        avatar: require("@/assets/images/avatars/1.png"),
        status: "offline",
        social_network_id: 1,
        typeChat: 1,
    },
    {
        id: 2,
        fullName: "Adalberto Granzin",
        role: "UI/UX Designer",
        about: "Toffee caramels jelly-o tart gummi bears cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.",
        avatar: require("@/assets/images/avatars/2.png"),
        status: "busy",
        social_network_id: 2,
        typeChat: 2,
    },
    {
        id: 3,
        fullName: "Joaquina Weisenborn",
        role: "Town planner",
        about: "Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.",
        avatar: require("@/assets/images/avatars/3.png"),
        status: "busy",
        social_network_id: 1,
        typeChat: 3,
    },
    {
        id: 4,
        fullName: "Verla Morgano",
        role: "Data scientist",
        about: "Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.",
        avatar: require("@/assets/images/avatars/4.png"),
        status: "online",
        social_network_id: 2,
        typeChat: 2,
    },
];
