<template>
    <b-sidebar 
        id="productsSidebar" 
        no-close-on-esc 
        right 
        hadow 
        title="Produtos"
        @shown="onShow"
    >
        <template v-slot:header="{ hide }">
            <div class="d-flex align-items-center py-4 px-3">
                <font-awesome-icon class="mr-5" icon="fa-solid fa-chevron-left" size="sm" @click="hide"/>
                <h5 class="m-0 text-blue">Produtos</h5>
            </div>
        </template>
        <div class="px-0 py-2 d-flex flex-column h-100 overflow-hidden">
            <div>
                <div class="input-group mb-3 mx-3 w-auto">
                    <span class="input-group-text">
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                    </span>
                    <input
                        v-model="search"
                        aria-label="Pesquisar"
                        class="form-control"
                        placeholder="Buscar um produto"
                        type="text"
                    >
                </div>
            </div>

            <div class="d-flex flex-column flex-grow-1 overflow-auto px-3">
                <div class="container">
                    <div v-for="(product, i) in products" :key="`product-row-${i}`" class="row my-2">
                        <div class="col-4">
                            <b-img
                                :alt="product.name"
                                class="m1"
                                rounded
                                :src="product.image"
                                :blank="!product.image"
                                blankColor="#777"
                                :width="75"
                                :height="75"
                            ></b-img>
                        </div>
                        <div class="col">
                            <h6 role="button" @click="selectedProduct = product" class="mb-2 font-weight-bold text-blue">
                                {{ product.name }}
                            </h6>
                            <h6>{{ product.value }}</h6>
                            <h6>{{ product.description }}</h6>
                        </div>
                    </div>
                    <span v-if="this.loading" class="spinner-border"></span>
                    <p v-if="products.length == 0 && !this.loading" class="m-0 text-center">
                        Nenhum produto encontrado
                    </p>
                </div>
            </div>
            <div v-if="$role(['products.create'])" class="container p-3 d-flex justify-content-center">
                <span
                    class=" font-weight-bold text-blue"
                    role="button"
                    v-b-modal.saveProductModal
                >
                    Criar um novo produto
                </span>
            </div>
        </div>
        <save-product-modal :chat="chat" v-model="selectedProduct" @save="() => getProducts()"/>
    </b-sidebar>
</template>

<script>
import api from "@/api";
import {BSidebar, BImg} from "bootstrap-vue";
import SaveProductModal from "./SaveProductModal.vue";

export default {
    components: {
        BSidebar,
        BImg,
        SaveProductModal
    },
    data() {
        return {
            products: [],
            selectedProduct: null,
            pagination: null,
            loading: false,
            search: null,
            searchTimeout: null,
        }
    },
    props: {
        chat: {
            type: Object,
            default: null
        },
    },
    watch: {
        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(this.getProducts, 500);
        },
    },
    methods: {
        onShow() {
            if(this.products.length > 0) return;

            this.getProducts()
        },
        getProducts(page = 1) {
            const params = {
                limit: 20,
                page,
                search: this.search
            };

            if(page == 1) this.products = [];

            this.loading = true;
            api.get('/products', { params })
            .then(response => {
                const { type, body, misc } = response.data;

                if(type == 'success') {
                    this.products = page == 1 ? body : [...this.products, ...body];
                    return this.pagination = misc;
                }

                throw response.data;
            })
            .catch(() => {
                this.products = [];
                this.pagination = null;
            })
            .finally(() => this.loading = false);
        }
    }
}
</script>

<style scoped>
h6 {
    text-align: left;
}

p {
    text-align: left;
    margin-bottom: 0;
}
</style>
