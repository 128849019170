<template>
    <b-modal
        body-class="position-unset"
        centered
        :title="(value && value.name) ? value.name : null"
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        lazy
        size="lg"
        v-model="show"
        @hidden="$emit('input', null)"
    >
        <loading v-if="loading" />
        <section v-if="value != null">
            <div v-if="type == 'video'" class="embed-responsive embed-responsive-16by9">
                <video controls>
                    <source :src="typeof value == 'string' ? value : value.url" type="video/mp4">
                </video>
            </div>

            <div v-else-if="type == 'image'" class="d-flex justify-content-center">
                <img 
                    class="img-fluid" 
                    :src="typeof value == 'string' ? value : value.url"
                >
            </div>

            <div v-else-if="value.mime && value.mime == 'application/pdf'" class="embed-responsive embed-responsive-16by9">
                <embed v-if="value.mime == 'application/pdf'" :src="value.url" type="application/pdf">
            </div>

            <div v-else class="text-center">
                <p>Não é possível visualizar esse arquivo, faça o download abaixo.</p>
            </div>
        </section>

        <template v-if="value != null" #modal-footer>
            <a target="_blank" :href="value.url" class="btn btn-secondary">
                <font-awesome-icon icon="fas fa-download" class="mr-2" /> Download
            </a>
        </template>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { acceptedMimes } from '@/api/constants';

export default {
    components: {
        BModal,
        Loading
    },
    props: {
        value: {
            type: [Object, String]
        }
    },
    data() {
        return {
            loading: false,
            show: false
        };
    },
    watch: {
        '$props.value'(val) {
            this.show = val != null;
        },
    },
    computed: {
        type() {
            const src = this.$props.value;

            if(src == null) return null;

            if(typeof src == 'string') {
                const ext = src.split('.')[1];

                if(acceptedMimes.image.includes(ext)) return 'image';
                if(acceptedMimes.video.includes(ext)) return 'video';
                return 'application';
            }

            return src.type;
        }
    },
    methods: {
        toast(message, type = "danger") {
            this.$root.$bvToast.toast(message, {
                title: 'Encurtar link',
                autoHideDelay: 2000,
                variant: type
            })
        },
    },
}

</script>


