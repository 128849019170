<template>
    <b-modal
        id="migratePhoneModal"
        centered
        title="Alterar contato"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        lazy
    >
        <loading v-if="loading" />

        <validation-observer ref="migratePhoneForm">
            <validation-provider
                #default="{ failed }"
                rules="required"
            >
                <input
                    type="text"
                    class="form-control m-0"
                    :class="{ 'is-invalid': failed }"
                    placeholder="Telefone"
                    v-model="phone"
                    v-mask="masker('phone')"
                    :masked="false"
                >
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary mr-2">
                Cancelar
            </button>
            <button @click="submit(ok)" class="btn btn-primary">
                Migrar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";
import { formatPhone } from "@/api/functions";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@/@core/utils/validations/validations";
import { mask } from 'vue-the-mask';

export default {
    components: {
        BModal,
        Loading,
        ValidationObserver,
        ValidationProvider
    },
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            phone_id: null,
            phones: [],
            phone: this.$props.value.phone,
            loading: false,
            required
        };
    },
    directives: {
        mask: (el, binding) => {
            if (!binding.value) return;
            mask(el, binding);
        },
    },
    methods: {
        formatPhone,
        toast(message, type = 'danger') {
            if (type == 'error') type = 'danger';
            this.$root.$bvToast.toast(message, {title: 'Migração de Números', variant: type})
        },
        submit(closeHandle) {
            this.$refs.migratePhoneForm.validate().then(success => {
                if(!success) return;
                this.loading = true;
                api.put(`/chats/${this.value._id}`, { phone: this.phone })
                .then(res => {
                    const { type, body } = res.data;

                    if(type == 'success') {
                        this.$emit('input', { ...this.value, ...body });
                        this.toast('Número migrado com sucesso.', 'success');
                        return closeHandle();
                    }
                    throw res;
                })
                .catch((res) => {
                    const { message } = res.data;
                    this.toast(message, 'error');
                })
                .finally(() => {
                    this.loading = false;
                    this.$router.push({name: 'chats/show', params: {phone: this.value.phone}})
                });
            });
        },
        masker(type) {
            const types = {
                phone: ['+## (##) #####-####', '+## (##) ####-####'],
            }

            return type in types ? types[type] : null;
        }
    },
}
</script>

<style>
.position-unset {
    position: unset !important;
}
</style>
