export const setScrollMessageId = async (id) => {
    return await new Promise((resolve) => {
        const ref = `message-${id}`;
        const message = document.getElementById(ref);
        if(!message) return resolve();
        message.scrollIntoView({ behavior: 'smooth' });
        message.classList.add('focused');
        setTimeout(() => {
            message.classList.remove('focused');
            resolve()
        }, 1000);
    })
}
