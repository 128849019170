<template>
    <b-modal
        id="saveProductModal"
        centered
        :title="product != null ? 'Editar produto' : 'Criar produto'"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        @show="load"
        @hide="reset"
    >
        <loading v-if="loading" />

        <validation-observer ref="saveProductForm">
            <div class="row">
                <div class="col-12 col-sm-3 mb-3">
                    <resizable-image-input
                        :options="{'aspect-ratio': 1}"
                        @input="(e) => {product.image = e; $forceUpdate()}"

                    >
                        <template v-slot:preview>
                            <b-img
                                class="m1"
                                rounded
                                :src="imageSrc(product.image)"
                                :blank="!product.image"
                                blankColor="#777"
                                :width="81"
                                :height="81"
                            ></b-img>
                        </template>
                    </resizable-image-input>
                </div>
                <div class="col-12 col-sm-9">
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        class="w-100"
                    >
                        <input
                            v-model="product.name"
                            type="text"
                            class="form-control m-0 mb-3"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            placeholder="Nome"
                        >
                    </validation-provider>
                    <validation-provider
                        #default="{ errors }"
                        rules="required"
                        class="w-100 mb-3"
                    >
                        <money
                            v-model="product.value"
                            type="text"
                            class="form-control m-0 mb-3"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            placeholder="Valor"
                            v-bind="money"
                        ></money>
                    </validation-provider>
                </div>
            </div>
            <validation-provider
                #default="{ errors }"
                rules="required"
                class="w-100"
            >
                <textarea
                    v-model="product.description"
                    type="text"
                    class="form-control m-0 mb-3"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    style="resize: none;"
                    placeholder="Descrição"
                    rows="3"
                ></textarea>
            </validation-provider>
            <validation-provider
                #default="{ errors }"
                rules="required|url"
                class="w-100"
            >
                <input
                    v-model="product.url"
                    type="text"
                    class="form-control m-0"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    placeholder="URL"
                >
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary mr-2">
                Cancelar
            </button>
            <button v-if="$role(['products.delete']) && product._id != null" @click="removeProduct(ok)" class="btn btn-danger">
                Remover
            </button>
            <button
                v-if="product._id == null || (product._id !== null && $role(['products.update']))"
                @click="saveProduct(ok)"
                class="btn btn-primary"
            >
                Salvar
            </button>
            <button v-if="product._id != null" @click="sendProduct(ok)" class="btn btn-success">
                Enviar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BModal, BImg } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import ResizableImageInput from "@/views/components/ResizableImageInput.vue";
import { required } from "@validations";
import api from "@/api";
import { Money } from 'v-money'

const productModel = {
    _id: null,
    name: null,
    image: null,
    description: null,
    value: 0,
    url: null
};

export default {
    components: {
        BModal,
        BImg,
        ValidationProvider,
        ValidationObserver,
        ResizableImageInput,
        Loading,
        Money
    },
    props: {
        chat: {
            type: Object,
            default: null
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            required,
            product: Object.assign({}, productModel),
            loading: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            },
        };
    },
    emits: ['save', 'input'],
    watch: {
        '$props.value'(val) {
            if(val != null)
                this.$root.$bvModal.show('saveProductModal');
        },
    },
    methods: {
        load() {
            if(this.$props.value != null) {
                return Object.assign(this.product, this.$props.value);
            }
            Object.assign(this.product, productModel);
        },
        reset() {
            this.$emit('input', null);
        },
        imageSrc(image) {
            try {
                if (image instanceof File)
                    return URL.createObjectURL(image);
                return image;
            } catch (error) {
                return null;
            }
        },
        sendProduct(closeHandler) {
            if(this.$props.chat == null) return;
            this.$refs.saveProductForm.validate().then(success => {
                if(!success) return;

                this.loading = true;
                const payload = {
                    phone: this.$props.chat.phone,
                    message: `*${this.product.name}*\n` +
                        `${this.product.description}\n` +
                        `_${Number(this.product.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}_\n` +
                        `LINK: ${this.product.url}\n`,
                }

                if (typeof this.product.image == 'string')
                    payload.file_url = this.product.image;
                else
                    payload.file = this.product.image;

                const formData = new FormData();
                Object.entries(payload).forEach(([key, value]) => formData.append(key, value));

                api.post(`/chats`, formData)
                .then(response => {
                    const { type, message } = response.data;

                    if(type === 'success') {
                        this.$bvToast.toast(message, {title: 'Produtos', variant: 'success'});
                        return closeHandler()
                    }

                    throw message;
                })
                .catch(error => {
                    let message = 'Não foi possível enviar produto.'
                    if(error.response) {
                        message = error.response.data.message;
                    }
                    if(typeof error == 'string') {
                        message = error
                    }
                    this.$bvToast.toast(message, {title: 'Produtos', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        },
        saveProduct(closeHandle) {
            this.$refs.saveProductForm.validate().then(success => {
                if(!success) return;

                this.loading = true;

                const id = this.product._id;

                const formData = new FormData();
                Object.entries(this.product).forEach(([key, value]) => {
                    if(key === 'image' && typeof value == 'string') return;
                    formData.append(key, value);
                });
                formData.append('_method', id ? 'PUT' : 'POST');

                api.post(id ?`/products/${id}` : '/products', formData)
                .then(response => {
                    const { type, message, body, errors } = response.data;

                    if(type === 'success') {
                        this.$bvToast.toast(message, {title: 'Produtos', variant: 'success'});
                        this.$emit('save', body);
                        return closeHandle()
                    }

                    throw errors ? errors[0] : message;
                })
                .catch(error => {
                    let message = 'Não foi possível salvar produto.'
                    if(error.response) {
                        message = error.response.data.message;
                    }
                    if(typeof error == 'string') {
                        message = error
                    }
                    this.$bvToast.toast(message, {title: 'Produtos', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        },
        removeProduct(closeHandle) {
            this.$swal({
                title: 'Tem certeza que deseja remover?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                reverseButtons: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    return api.delete(`/products/${this.product._id}`)
                        .then(res => {
                            const {type, message} = res.data;
                            if (type !== 'success') throw new Error(message)

                            this.$bvToast.toast(message, {title: 'Produtos', variant: 'success'});
                            this.$emit('save', null);
                            return closeHandle();
                        })
                        .catch(() => {
                            this.$swal('Erro', 'Não foi possível remover este produto', 'danger');
                        })
                },
                allowOutsideClick: () => !this.$swal.isLoading()
            });
        },
    },
}
</script>

<style scoped>
.position-unset {
    position: unset !important;
}
</style>
