<template>
    <b-sidebar
        id="searchMessageSidebar"
        backdrop
        body-class="d-flex flex-column"
        lazy
        no-close-on-esc
        right
        shadow
        @change="reset"
    >
        <template v-slot:header="{ hide }">
            <div class="d-flex align-items-center py-4 px-3">
                <font-awesome-icon class="mr-5" icon="fa-solid fa-chevron-left" size="sm" @click="hide"/>
                <h5 class="m-0 text-blue">Procurar</h5>
            </div>
        </template>
        <div class="px-3 py-2">
            <div class="input-group mb-0">
                <span class="input-group-text">
                    <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                </span>
                <input
                    v-model="search"
                    aria-label="Pesquisar"
                    class="form-control"
                    placeholder="Buscar uma mensagem"
                    type="text"
                >
            </div>
        </div>

        <p
            v-if="messages == null && !loading"
            class="text-center w-75 mt-3"
        >
            Pesquisar mensagens com {{ contact.name }}.
        </p>
        <p
            v-if="messages != null && messages.length == 0 && !loading"
            class="text-center w-75 mt-3"
        >
            Nenhuma mensagem encontrada
        </p>

        <section
            class="overflow-auto flex-grow-1 custom-scroll"
            @scroll="loadMore"
        >
            <span
                v-for="(message, i) in messages"
                :key="`message-search-${i}`"
                class="p-3 d-flex flex-column align-items-start text-left chat-search-message"
                @click="() => setScrollMessageId(message._id)"
            >
                <small>{{ getShortStringTime(message.time) }}</small>
                <small class="text-truncate w-100" v-html="applyHighlight(message.message)"></small>
            </span>
            <span v-if="loading" class="spinner-border mt-3 text-center mx-auto" role="status"></span>
        </section>
    </b-sidebar>
</template>

<script>
import {BSidebar} from "bootstrap-vue";
import {clearTagsMessage, getShortStringTime} from '@/api/functions';
import api from "@/api";

export default {
    components: {
        BSidebar
    },
    watch: {
        search() {
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(this.searchMessages, 500);
        }
    },
    props: {
        contact: {
            type: Object
        }
    },
    data() {
        return {
            searched: null,
            search: null,
            searchTimeout: null,
            loading: false,
            messages: null,
            pagination: null
        }
    },
    methods: {
        getShortStringTime,
        reset(visible) {
            if (!visible) {
                setTimeout(() => {
                    this.search = null;
                    clearTimeout(this.searchTimeout);
                    this.messages = null;
                    this.loading = false;
                    this.searched = null
                }, 500);
            }
        },
        applyHighlight(text) {
            text = clearTagsMessage(text);
            if (this.searched != null) {
                text = text.replaceAll(new RegExp(`(${this.search})`, 'ig'), `<span class="text-blue font-weight-bold">$1</span>`);
            }
            return text;
        },
        searchMessages(page = 1) {
            if (this.search.length < 2) return;
            const contactPhone = this.contact.phone;
            const params = {search: this.search, order: 'desc', page, limit: 20};

            if (page == 1) {
                this.messages = [];
            }
            this.loading = true;

            api.get(`/chats/${contactPhone}/messages`, {params})
                .then(res => {
                    const {type, body, message, misc} = res.data;

                    if (type == 'success') {
                        this.searched = this.search;
                        this.pagination = misc;
                        return this.messages = [...this.messages, ...body];
                    }

                    throw message;
                })
                .catch(error => {
                    console.log(error);
                    this.messages = [];
                })
                .finally(() => this.loading = false);
        },
        loadMore({target: {scrollTop, clientHeight, scrollHeight}}) {
            if (!(scrollTop + clientHeight >= scrollHeight - 80)) return;
            if (this.pagination == null || this.pagination.last_page <= this.pagination.current_page) return;
            if (this.loading) return;
            this.searchMessages(this.pagination.current_page + 1);
        },
        setScrollMessageId(id) {
            const ref = `message-${id}`;
            document.getElementById(ref).scrollIntoView({behavior: 'smooth'});
        }
    }
}
</script>

<style lang="scss" scoped>
h6 {
    text-align: left;
    margin-bottom: -2px;
}

p {
    text-align: left;
    margin-left: 36px;
}

.chat-search-message {
    cursor: pointer;

    &:hover {
        background-color: #EEE;
    }
}
</style>
