<template>
    <div
        class="sidebar-toggle d-block d-lg-none mr-1"
        @click="toggle"
    >
        <feather-icon
            icon="MenuIcon"
            class="cursor-pointer"
            size="21"
        />
    </div>
</template>

<script>

export default {
    methods: {
        toggle() {
            const sidebar = document.querySelector('.sidebar-container');
            if(sidebar.classList.contains('show')) {
                return sidebar.classList.remove('show');
            }
            sidebar.classList.add('show')
        }
    }
}
</script>
