<template>
    <b-modal
        v-if="contact.customer != null"
        id="customerDataModal"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        hide-footer
        size="lg"
        title="Dados dos clientes"
        @show="onShow"
    >
        <div class="d-flex mb-3">
            <div class="flex-grow-1 mr-3">
                <label for="">Nome</label>
                <input
                    :value="contact.customer.name"
                    class="form-control"
                    readonly
                    type="text"
                >
            </div>
            <div class="d-flex flex-column">
                <label class="mr-1 text-black px-3">Score</label>
                <b-form-rating
                    :value="contact.customer.score || 0"
                    class="text-center shadow-none"
                    inline
                    no-border
                    readonly
                ></b-form-rating>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <label for="">Email</label>
                <input
                    :value="contact.customer.email"
                    class="form-control"
                    readonly
                    type="text"
                >
            </div>
            <div class="col">
                <label for="">Celular</label>
                <input
                    :value="contact.customer.phone"
                    class="form-control"
                    readonly
                    type="text"
                >
            </div>
        </div>
        <div>
            <h3 class="text-dark font-weight-bold text-left mt-5 mb-2">
                Mensagens Favoritadas
                <hr>
            </h3>
        </div>

        <p v-if="favoriteMessages.length == 0 && !loading">Nenhuma mensagem favorita encontrada</p>
        <div v-else class="chat-app-window position-relative">
            <loading v-if="loading"></loading>
            <vue-perfect-scrollbar class="user-chats scroll-area px-0" style="max-height: 250px;">
                <div class="chats">
                    <div
                        class="chat"
                    >
                        <div
                            v-for="(message, i) in favoriteMessages"
                            :key="`message-log-${i}-${message._id}`"
                            :class="{left: !message.from_me}"
                            class="chat-line"
                        >
                            <div class="chat-content">
                                <font-awesome-icon
                                    class="chat-bookmark text-blue"
                                    icon="fa-solid fa-bookmark"
                                    size="lg"
                                    @click="() => removeFavorite(message._id)"
                                />
                                <p class="text-left text-blue font-weight-bold">
                                    {{
                                        message.from_me ? user.name : contact.name
                                    }}
                                </p>
                                <p class="text-justify font-weight-normal">
                                    <span v-html="message.message"></span>
                                </p>
                            </div>
                            <small v-if="message.time" class="chat-time">{{ getStringTime(message.time) }}</small>
                        </div>
                    </div>
                </div>
            </vue-perfect-scrollbar>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormRating,
} from "bootstrap-vue";
import {getStringTime} from '@/api/functions';
import Loading from '@/views/components/Loading.vue';
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import api from "@/api";

export default {
    components: {
        BModal,
        BFormRating,
        Loading,
        VuePerfectScrollbar
    },
    props: {
        contact: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            favoriteMessages: [],
            loading: false
        };
    },
    methods: {
        onShow() {
            this.favoriteMessages = [];
            if (this.contact != null)
                this.getFavoriteMessages();
        },
        getStringTime,
        getFavoriteMessages() {
            this.loading = true;

            const phone = this.$props.contact.phone;
            api.get(`/chats/${phone}/messages`, {params: {favorite: 1}})
                .then(response => {
                    const {type, body} = response.data;

                    if (type == 'success') {
                        return this.favoriteMessages = body;
                    }
                    throw response;
                })
                .catch((error) => {
                    console.log(`getFavoriteMessages: ${error}`);
                    this.$bvToast.toast('Não foi possível carregar mensagens favoritas.', {
                        title: 'Mensagens Favoritas',
                        variant: 'danger'
                    });
                    this.favoriteMessages = [];
                })
                .finally(() => this.loading = false);
        },
        removeFavorite(id) {
            this.$swal({
                title: 'Tem certeza?',
                text: 'Deseja remover essa mensagem da lista de favoritos?',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Remover',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const phone = this.$props.contact.phone;
                    return api.put(`/chats/${phone}/messages/${id}`, {favorite: 0});
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const {type} = result.value.data;

                        if (type == 'success') {
                            const index = this.favoriteMessages.findIndex(e => e._id == id);
                            if (index >= 0)
                                this.favoriteMessages.splice(index, 1);
                            return;
                        }
                        throw result.value.data;
                    }
                })
                .catch((error) => {
                    console.log(`removeFavorite: ${error}`);
                    this.$bvToast.toast('Não foi possível remover mensagem dos favoritos.', {
                        title: 'Mensagens Favoritas',
                        variant: 'danger'
                    });
                })
        },
    },
    computed: {
        user: function () {
            return this.$store.getters['user/get'];
        },
    }
}
</script>

<!--<style>-->
<!--.bi-bookmark-fill {-->
<!--    margin-top: -9px;-->
<!--}-->
<!--</style>-->
