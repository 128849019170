<template>
    <div>
        <label role="button" class="m-0" for="resizable-image-input">
            <slot name="preview">
                <img
                    width="230"
                    class="img-thumbnail rounded-circle"
                    style="object-fit: cover; object-position: center; aspect-ratio: 1;"
                    :src="preview"
                    :onerror="`this.src='${require('@/assets/images/default/default-user.jpg')}'`"
                    alt="">
            </slot>
        </label>
        <input id="resizable-image-input" type="file" accept=".jpg,.jpeg,.png" :disabled="disabled" hidden @change="handleInputFile">

        <b-modal v-model="crop" title="Recortar" class="position-relative">
            <loading v-if="loading"></loading>
            <div class="cropper">
                <vue-cropper
                    ref="cropper"
                    :src="cropSrc"
                    v-bind="options"
                ></vue-cropper>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="d-flex align-items-center jusitfy-content-end">
                    <button :disabled="loading" @click="cancel" class="btn btn-secondary mx-2">Cancelar</button>
                    <button :disabled="loading" @click="save" class="btn btn-primary">Recortar</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import Loading from '@/views/components/Loading';

export default {
    components: {
    BModal,
    VueCropper,
    Loading
},
    props: ['src', 'options', 'default', 'disabled'],
    emits: ['input'],
    data() {
        return {
            previewSrc: this.$props.src,
            crop: false,
            cropSrc: null,
            loading: false
        }
    },
    watch: {
        src(value) {
            this.previewSrc = value;
        }
    },
    computed: {
        preview() {
            if(this.previewSrc != null) return this.previewSrc;
            if(this.$props.src != null) return this.$props.src;
            return '/assets/imgs/default-image.jpg';
        }
    },
    methods: {
		save() {
            this.loading = true;
			const canvas = this.$refs.cropper.getCroppedCanvas();

            try {
                this.previewSrc = canvas.toDataURL();
                canvas.toBlob(blob => {
                    const file = new File([blob], "image.png");
                    this.$emit('input', file);
                    this.crop = false;
                    this.loading = false;
                    this.cropSrc = null;
                }, 'image/jpeg', 0.9);
            } catch(e) {
                console.log(e)
                this.crop = false;
                this.loading = false;
                this.$swal('Erro ao recortar imagem', 'Não foi possível recortar imagem.', 'error');
            }
		},
        handleInputFile(event) {
            const accepted = ['jpg', 'png', 'jpeg'];

            if (!(event.target.files && event.target.files[0])) return;

            const file = event.target.files[0];
            event.target.value = null;

            if(!accepted.includes(file.name.split('.').pop())) {
                return this.$swal('Formato não suportado', `O formato do arquivo não é suportado. [${accepted.join(', ')}]`, 'error');
            }

            if(file.size && Number(file.size) / 1024 / 1024 > 2) {
                return this.$swal('Arquivo muito grande', `Somente arquivos de até 2 MB são aceitos.`, 'error');
            }

            var reader = new FileReader();

            reader.onload = (e) => {
               this.cropSrc = e.target.result;
               this.crop = true;
            }

            reader.readAsDataURL(file);
        }
    }
}
</script>

<style lang="scss" scoped>
    .cropper {
        max-height: 80vh;
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
</style>
