<template>
    <div id="my-container">
        <b-popover
            ref="popover"
            container="my-container"
            placement="bottom"
            target="pauseMessagesPopover"
            triggers="click"
            @hide="reset"
        >
            <div class="p-3">
                <h5 class="text-blue">
                    Pausar envio de mensagens {{ pausedUntil() === null ? 'durante' : '' }}
                </h5>

                <div v-if="pausedUntil() != null">
                    <p>Pausado até <b>{{ pausedUntil().format('DD/MM/YYYY HH:mm') }}</b>.</p>
                    <button
                        class="btn btn-primary w-100"
                        @click="resumeMessages"
                    >
                        Retomar
                    </button>
                </div>

                <div v-else>
                    <div class="d-flex flex-column" style="font-size: 1.2rem;">
                        <span @click="() => setTime(7)" role="button" class="my-1 text-secondary">
                            01 semana
                        </span>
                        
                        <span @click="() => setTime(30)" role="button" class="my-1 text-secondary">
                            01 mês
                        </span>
                        
                        <span @click="() => setTime(60)" role="button" class="my-1 text-secondary">
                            02 meses
                        </span>
                        
                        <span @click="() => setTime(180)" role="button" class="my-1 text-secondary">
                            06 meses
                        </span>

                        <span @click="() => setTime(365)" role="button" class="my-1 text-secondary">
                            01 ano
                        </span>
                    </div>

                    <div class="my-2">
                        <label class="m-0 mt-2" for="filter-start">Até</label>
                        <div class="input-group">
                            <input
                                v-model="pauseDateTime.date"
                                :min="today"
                                class="form-control"
                                type="date"
                            >
                            <input
                                v-model="pauseDateTime.time"
                                class="form-control"
                                type="time"
                            >
                        </div>
                        <small
                            v-if="this.fullDate != null && this.fullDate.isBefore(moment())"
                            class="text-danger"
                        >
                            Insira uma data posterior
                        </small>
                    </div>
                    <button
                        :disabled="this.fullDate == null || this.fullDate.isBefore(moment())"
                        class="btn btn-primary w-100"
                        @click="savePauseMessages"
                    >
                        Pausar
                    </button>
                </div>
            </div>
        </b-popover>
    </div>
</template>

<script>
import api from '@/api';
import {BPopover} from 'bootstrap-vue';
import moment from 'moment';

const dateTimeModel = {
    date: null,
    time: null
};

export default {
    components: {
        BPopover,
    },
    data() {
        return {
            pauseDateTime: Object.assign({}, dateTimeModel),
        };
    },
    props: {
        value: {
            type: Object
        }
    },
    emits: ['input'],
    computed: {
        fullDate() {
            const text = Object.values(this.pauseDateTime).join(' ');
            const date = moment(text);
            if (isNaN(date)) return null;
            return date;
        },
        today() {
            return moment().format('YYYY-MM-DD');
        }
    },
    methods: {
        reset() {
            this.pauseDateTime = Object.assign({}, dateTimeModel);
        },
        setTime(days) {
            const target = moment().add({ days });
            this.pauseDateTime.date = target.format('YYYY-MM-DD');
            this.pauseDateTime.time = target.format('HH:mm');
        },
        formatInterval(key, num) {
            try {
                const names = {
                    minutes: ['minuto', 'minutos'],
                    hours: ['hora', 'horas'],
                    days: ['dia', 'dias'],
                    weeks: ['semana', 'semanas'],
                    months: ['mês', 'meses'],
                    years: ['ano', 'anos'],
                }
                return `${num} ${names[key][Number(num > 1)]}`;
            } catch (e) {
                return '';
            }
        },
        savePauseMessages() {
            const dateStr = this.fullDate.format('DD/MM/YYYY HH:mm');
            this.$swal({
                title: 'Pausar mensagens',
                html: `Deseja pausar toda a comunicação com esse contato até <b>${dateStr}<b/>?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                reverseButtons: true,
                preConfirm: () => {
                    const id = this.$props.value._id;
                    return api.put(`/chats/${id}`, {paused_until: this.fullDate});
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const {type, body} = result.value.data;

                        if (type == 'success') {
                            this.$emit('input', Object.assign(this.$props.value, body));
                            return this.$forceUpdate();
                        }
                        throw result.value.data;
                    }
                })
                .catch((error) => {
                    console.log(`savePauseMessages: ${error}`);
                    this.$bvToast.toast('Não foi possível pausar o envio de mensagens.', {
                        title: 'Pausar envio de mensagens',
                        variant: 'danger'
                    });
                })
        },
        resumeMessages() {
            this.$swal({
                title: 'Retomar mensagens',
                html: `Deseja retomar a comunicação com esse contato?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                confirmButtonColor: "#317f98",
                showLoaderOnConfirm: true,
                reverseButtons: true,
                preConfirm: () => {
                    const id = this.$props.value._id;
                    return api.put(`/chats/${id}`, { paused_until: -1 });
                }
            })
                .then((result) => {
                    if (result.isConfirmed && result.value.data.type) {
                        const {type, body} = result.value.data;

                        if (type == 'success') {
                            this.$emit('input', Object.assign(this.$props.value, body));
                            return this.$forceUpdate();
                        }
                        throw result.value.data;
                    }
                })
                .catch((error) => {
                    console.log(`resumeMessages: ${error}`);
                    this.$bvToast.toast('Não foi possível retomar o envio de mensagens.', {
                        title: 'Retomar envio de mensagens',
                        variant: 'danger'
                    });
                })
        },
        pausedUntil() {
            if (this.$props.value && this.$props.value.paused_until) {
                const date = moment(this.$props.value.paused_until);
                return isNaN(date) || date.isSameOrBefore(moment()) ? null : date
            }
            return null;
        },
    }
};
</script>
