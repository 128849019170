<template>
    <section
        class="px-0 w-100 overflow-hidden d-flex flex-grow-1 position-relative"
    >
        <span v-if="showGoDown" class="go-down-button" @click="goDown">
            <font-awesome-icon
                class="text-white"
                icon="fas fa-chevron-down"
                size="sm"
            />
        </span>
        <div class="chats d-flex flex-column w-100 flex-grow-1">
            <div
                id="chatScrollDiv"
                @scroll="onScroll"
                class="w-100 flex-grow-1 chat custom-scroll user-chats d-flex flex-column-reverse"
            >
                <div
                    v-for="(message, i) in messages"
                    :id="`message-${message._id}`"
                    :key="`message-log-${i}-${message._id}`"
                    :class="{ left: !message.from_me }"
                    class="chat-line px-2 py-2"
                >
                    <div
                        class="chat-content"
                        :class="{ wrong: message.from_wrong_phone }"
                    >
                        <b-dropdown
                            :dropleft="message.from_me"
                            :dropright="!message.from_me"
                            class="chat-options"
                            no-caret
                            variant="link"
                        >
                            <template #button-content>
                                <font-awesome-icon
                                    icon="fa-solid fa-chevron-down"
                                    size="sm"
                                    :style="{
                                        background: message.from_me ? '#cee5ff' : '#F7F7F7',
                                        boxShadow: `0 0 12px ${message.from_me ? '#cee5ff' : '#F7F7F7'}`
                                    }"
                                />
                            </template>
                            <b-dropdown-item
                                v-if="
                                    $role('customers.update') &&
                                    chat.customer &&
                                    !message.from_me
                                "
                                @click="() => saveMessage(message.message)"
                            >
                                Salvar Mensagem
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="
                                    () =>
                                        updateMessage(
                                            message._id,
                                            { favorite: !message.favorite },
                                            'Mensagens Favoritas'
                                        )
                                "
                            >
                                {{
                                    message.favorite
                                        ? "Remover mensagem dos favoritos"
                                        : "Favoritar mensagem"
                                }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="
                                    message.status === 'readed' &&
                                    !message.from_me
                                "
                                @click="
                                    () =>
                                        updateMessage(message._id, {
                                            status: 'received',
                                        })
                                "
                            >
                                Marcar como não lida
                            </b-dropdown-item>
                        </b-dropdown>
                        <p class="text-left text-blue font-weight-normal">
                            <font-awesome-icon
                                icon="fas fa-circle-info"
                                size="sm"
                                v-b-tooltip="'Essa mensagem foi enviada em um número de disparo diferente do atrelado a este cliente'"
                                v-if="message.from_wrong_phone"
                            />
                            <span v-html="getMessageName(message)"></span>
                        </p>
                        <div class="text-justify font-weight-normal">
                            <div v-if="message.file != null">
                                <div
                                    v-if="message.file.mime === 'image/webp'"
                                    @click="selectedPreview = message.file"
                                    class="w-100 my-2"
                                >
                                    <div style="max-width: 150px;" class="ratio ratio-1x1">
                                        <div>
                                            <img
                                                class="img-fluid"
                                                :src="message.file.url"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-else-if="message.file.type === 'image'"
                                    @click="selectedPreview = message.file"
                                    class="preview w-100 my-2"
                                >
                                    <div class="overlay"></div>
                                    <img
                                        style="border-radius: 1rem"
                                        :src="message.file.url"
                                        class="img-fluid"
                                    />
                                </div>
                                <div
                                    v-else-if="message.file.type === 'video'"
                                    @click="selectedPreview = message.file"
                                    class="preview video-preview w-100 my-2"
                                >
                                    <div class="overlay"></div>
                                    <div>
                                        <img
                                            alt=""
                                            style="border-radius: 1rem"
                                            :src="message.file.thumbnail"
                                        />
                                    </div>
                                    <font-awesome-icon
                                        icon="fas fa-play"
                                        size="xl"
                                        class="text-white"
                                    />
                                </div>
                                <div
                                    v-else-if="message.file.type === 'audio'"
                                    class="w-100 mt-2"
                                >
                                    <audio-player 
                                        style="min-width: 200px;" 
                                        :src="message.file.url"
                                    />
                                </div>
                                <div
                                    v-else-if="message.file.type === 'application'"
                                    @click="selectedPreview = message.file"
                                    class="preview w-100 my-2"
                                >
                                    <div class="overlay"></div>
                                    <div class="p-3 d-flex align-items-center">
                                        <font-awesome-icon
                                            size="xl"
                                            :icon="`fas ${
                                                message.file.mime === 'application/pdf'
                                                    ? 'fa-file-pdf'
                                                    : 'fa-file'
                                            }`"
                                            class="text-orange mr-3"
                                        />
                                        <p
                                            class="flex-grow-1"
                                            style="word-break: break-all"
                                        >
                                            {{ message.file.name }}
                                        </p>
                                    </div>
                                </div>
                                <em
                                    v-else
                                    class="d-flex align-items-center text-secondary"
                                >
                                    <font-awesome-icon
                                        icon="fas fa-ban"
                                        class="mr-2"
                                    />
                                    Arquivo removido
                                </em>
                            </div>
                            <span
                                v-if="message.message != null"
                                v-html="setTagsMessage(message.message)"
                            ></span>
                            <em
                                class="d-flex align-items-center text-secondary"
                                v-if="
                                    message.message == null &&
                                    message.file == null
                                "
                            >
                                <font-awesome-icon
                                    icon="fas fa-ban"
                                    class="mr-2"
                                />
                                Mensagem ou arquivo não encontrados.
                            </em>
                        </div>
                    </div>
                    <small v-if="message.time" class="chat-time mb-0">{{
                        getStringTime(message.time)
                    }}</small>
                </div>
                <p v-if="!loading && messages.length === 0" class="m-0">
                    Envie uma mensagem para começar a conversa
                </p>
                <div
                    v-if="loading"
                    class="d-flex align-items-center justify-content-center py-3 w-100"
                >
                    <span
                        class="spinner-border spinner-border"
                        role="status"
                    ></span>
                </div>
            </div>
            <preview-modal v-model="selectedPreview" />
            <save-message-to-customer-modal
                v-if="chat.customer"
                @hidden="() => selectedMessage = null"
                @update="(customer) => $emit('updateCustomer', customer)"
                :customer="chat.customer"
                v-model="selectedMessage"
            />
        </div>
    </section>
</template>

<script>
import api from "@/api";
import { getStringTime, setTagsMessage } from "@/api/functions";
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import { setScrollMessageId } from "./functions";
import PreviewModal from "./modals/PreviewModal.vue";
import AudioPlayer from "../../../components/AudioPlayer.vue";
import SaveMessageToCustomerModal from "./modals/SaveMessageToCustomerModal.vue";

export default {
    components: {
        BDropdown,
        BDropdownItem,
        PreviewModal,
        AudioPlayer,
        SaveMessageToCustomerModal
    },
    props: {
        chat: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            messageList: [],
            pagination: null,
            timeout: null,
            reading: false,
            loading: false,
            showGoDown: false,
            selectedPreview: null,
            selectedMessage: null
        };
    },
    created() {
        this.fetchMessages();
    },
    watch: {
        messageList(newChat, oldChat) {
            const container = document.getElementById("chatScrollDiv");
            if (container.scrollTop === 0 && container.scrollHeight < 1000) {
                this.readMessages();
            }

            if (
                newChat.length !== oldChat.length &&
                this.$route.query.message == null &&
                container.scrollTop < 1000
            ) {
                setTimeout(() => {
                    const container = document.getElementById("chatScrollDiv");
                    container.scrollTop = 0;
                }, 50);
            }
        },
        "$store.state.chat": {
            handler: function (val) {
                const messages = val.messages;
                const id = this.chat._id;
                if (id in messages) {
                    const array = [...messages[id], ...this.messageList];
                    const arrayUniqueByKey = [
                        ...new Map(
                            array.map((item) => [item["message_id"], item])
                        ).values(),
                    ];
                    this.messageList = arrayUniqueByKey;
                }
            },
            deep: true,
        },
    },
    mounted() {
        if (this.$route.query.message == null) {
            setTimeout(() => this.goDown(), 50);
        } else {
            setTimeout(() => {
                setScrollMessageId(this.$route.query.message).then(() => {
                    this.$router.replace({ ...this.$route, query: {} });
                });
            }, 50);
        }
    },
    methods: {
        getStringTime,
        setTagsMessage,
        getMessageName(message) {
            if (!message.from_me)
                return this.chat.name;
            
            if (message.customer_shot) {
                if (message.customer_shot.campaign)
                    return `Enviada pela campanha <b>${message.customer_shot.campaign.name}</b>`
                return 'Enviada por campanha'
            }
            return this.user.name
        },  
        fetchMessages(page = 1) {
            this.loading = true;
            const params = {
                page,
                limit: 20,
                order_column: "time",
                order: "desc",
            };

            api.get(`/chats/${this.chat.phone}/messages`, { params })
                .then((res) => {
                    const { type, body, misc } = res.data;

                    if (type === "success") {
                        const ids = this.messageList.map((e) => e._id);
                        const list = [
                            ...Array.from(body).filter(
                                (e) => !ids.includes(e._id)
                            ),
                            ...this.messageList,
                        ];
                        this.messageList = list;
                        this.pagination = misc;
                    }
                })
                .finally(() => (this.loading = false));
        },
        goDown() {
            const container = document.getElementById("chatScrollDiv");
            container.scrollTop = 0;
            this.readMessages();
        },
        updateMessage(id, payload, title = "Mensagens") {
            api.put(`/chats/${this.chat.phone}/messages/${id}`, payload)
                .then((res) => {
                    const { type, body, message } = res.data;

                    if (type === "success") {
                        const index = this.messageList.findIndex(
                            (e) => e._id == id
                        );
                        if (index >= 0) {
                            Object.assign(this.messageList[index], body);
                        }
                    }
                    this.$bvToast.toast(message, {
                        title,
                        variant: type === "success" ? "success" : "danger",
                    });
                })
                .catch((error) => {
                    let message = "Não foi possível atualizar a mensagem.";
                    if (error.response) message = error.response.message;

                    this.$bvToast.toast(message, { title, variant: "danger" });
                });
        },
        onScroll(event) {
            this.showGoDown = -event.target.scrollTop > 700;

            if (
                !(
                    event.target.scrollTop !== 0 ||
                    this.loading ||
                    this.pagination.current_page >= this.pagination.last_page
                )
            ) {
                this.fetchMessages(this.pagination.current_page + 1);
            }

            if (event.target.scrollTop < 500) {
                this.readMessages();
            }
        },
        readMessages() {
            if (this.reading || this.chat.unreaded_messages == 0) return;

            this.reading = true;

            api.post(`/chats/${this.chat.phone}/messages/read`).finally(
                () => (this.reading = false)
            );
        },
        saveMessage(message) {
            this.selectedMessage = message;
            this.$bvModal.show("saveMessageToCustomerModal");
        }
    },
    computed: {
        user: function () {
            return this.$store.getters["user/get"];
        },
        messages() {
            const messages = this.messageList;
            messages.sort((a, b) => b.time - a.time);
            return messages;
        },
    },
};
</script>

<style lang="scss" scoped>
.go-down-button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 99;
    bottom: 0;
    margin-bottom: 10px;
    right: 1rem;
    margin-left: auto;
    border-radius: 50%;
    background-color: #0009;
    transition: 300ms;
    cursor: pointer;

    &:hover {
        background-color: #000f;
    }
}

.preview {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    cursor: pointer;

    &.video-preview {
        aspect-ratio: 16/9;
    }

    .overlay {
        transition: 200ms;
        opacity: 0.05;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: black;
        z-index: 10;
    }
    &:hover .overlay {
        opacity: 0.3;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .fa-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 11;
    }
}
</style>
