<template>
    <b-modal
        :id="id"
        :no-close-on-backdrop="loading || isTransfer()"
        :no-close-on-esc="loading || isTransfer()"
        :title="isTransfer() ? 'Conversa transferida' : 'Transferir conversa'"
        :visible="isTransfer()"
        body-class="position-unset"
        centered
        content-class="p-4 position-relative"
        footer-border-variant="light"
        header-border-variant="light"
        lazy
        size="lg"
        @cancel="onClose"
        @close="onClose"
    >
        <loading v-if="loading"/>

        <validation-observer ref="transferForm">
            <h6 v-if="isTransfer()" class="text-black-50 mb-3">O atendente anterior não pôde finalizar esta
                conversa.</h6>
            <h6 v-else class="text-black-50 mb-3">Transfira a conversa caso não possa finalizar o atendimento hoje.</h6>
            <div class="d-flex flex-row text-left mb-3">
                <div class="d-flex flex-column text-left">
                    <label class="m-0 text-black-50" for="filter-start">Inicio</label>
                    <input
                        id="filter-start"
                        type="time"
                        class="form-control m-0 flex-grow-1"
                        v-model="transfer.started_at"
                        :readonly="isTransfer()"
                    >
                </div>
                <div class="d-flex flex-column text-left mx-2">
                    <label class="m-0 text-black-50" for="filter-end">Final</label>
                    <input
                        id="filter-end"
                        type="time"
                        class="form-control m-0 flex-grow-1"
                        v-model="transfer.ended_at"
                        :readonly="isTransfer()"
                    >
                </div>
            </div>
            <validation-provider
                #default="{ errors }"
                class="w-100"
                rules="required"
            >
                <textarea
                    v-model="transfer.comment"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    :readonly="isTransfer()"
                    class="form-control m-0 flex-grow-1"
                    placeholder="Faça uma breve descrição do atendimento"
                    rows="5"
                ></textarea>
            </validation-provider>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button :disabled="loading" class="btn btn-outline-primary px-5 mr-2" @click="cancel">
                Cancelar
            </button>
            <button :disabled="loading" class="btn btn-primary px-5" @click="() => transferChat(ok)">
                {{ isTransfer() ? 'Aceitar' : 'Transferir' }}
            </button>
        </template>
    </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required} from "@validations";
import Loading from "@/views/components/Loading.vue";
import api from "@/api";

export default {
    components: {
        BModal,
        Loading,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        value: {
            type: [Object, Array]
        },
        id: {
            type: String,
            default: () => "transferChatModal"
        }
    },
    computed: {
        isMass() {
            const value = this.$props.value;
            if(value == null) return false;
            return value.constructor == Array;
        }
    },
    emits: ['input'],
    data() {
        return {
            transfer: this.isTransfer() ? Object.assign({}, this.$props.value.transfer) : {
                started_at: null,
                ended_at: null,
                contact_id: null,
                comment: null
            },
            required,
            loading: false
        };
    },
    methods: {
        onClose() {
            if (!this.isTransfer()) return;

            this.$router.replace({name: 'chats'});
        },
        isTransfer() {
            const chat = this.$props.value;
            if(this.isMass) return false;
            return !(chat == null || chat.transfer == null);
        },
        async transferChat(closeHandle) {
            const transfer = this.isTransfer();
            let result = this.isTransfer();

            if (!transfer) result = await this.$refs.transferForm.validate();
            if (!result) return;

            this.loading = true;

            if(this.isMass) {
                this.transfer.contact_id = this.$props.value.map(e => e._id);
            } else {
                this.transfer.contact_id = this.$props.value._id;
            }

            return api({
                method: transfer ? 'PUT' : 'POST',
                url: transfer ? `/chats/transfers/${this.transfer._id}` : `/chats/transfers`,
                data: transfer ? null : this.transfer
            })
                .then(response => {
                    const {type, message, body} = response.data;

                    if (type == 'success') {
                        this.$root.$bvToast.toast(message, {title: 'Tranferências', variant: 'success'});
                        var chat = this.$props.value;
                        if(this.isMass) {
                            chat = chat.map((e, i) => {
                                return {
                                    ...e,
                                    transfer: body[i]
                                }
                            })
                        } else {
                            chat.transfer = transfer ? null : body;
                        }
                        this.$emit('input', chat);
                        this.transfer = !transfer ? chat : {
                            started_at: null,
                            ended_at: null,
                            contact_id: this.isMass ? null : this.$props.value._id,
                            comment: null
                        };
                        if (!transfer && this.$route.name != 'chats') return setTimeout(() => this.$router.replace({name: 'chats'}), 300)
                        else return closeHandle()
                    }

                    throw message;
                })
                .catch((error) => {
                    let message = transfer
                        ? 'Não foi possível aceitar a transferência da conversa.'
                        : 'Não foi possível transferir conversa.';
                    if (error.response) message = error.response.data.message;
                    if (typeof error == 'string') message = error;
                    console.log(`transferChat: ${error}`);
                    this.$bvToast.toast(message, {title: 'Transferências', variant: 'danger'});
                })
                .finally(() => this.loading = false);
        }
    },
}
</script>
