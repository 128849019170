<template>
    <div class="d-flex flex-column justify-content-center" style="height: 40px;">
        <div class="d-flex align-items-center">
            <font-awesome-icon 
                class="text-secondary" 
                size="lg"
                width="40"
                :spin="loading"
                :icon="`fas fa-${iconShow}`"
                @click="playing = !playing"
                role="button"
            />

            <div class="player-timeline">
                <div :style="progressStyle" class="player-progress"></div>
                <div @click="seek" class="player-seeker"></div>
            </div>
        </div>
        <div style="padding-left: 40px;" class="d-flex w-100 justify-content-between">
            <small>{{ convertTimeHHMMSS(currentTime) }}</small>
            <small>{{ durationSeconds > 0 ? convertTimeHHMMSS(durationSeconds) : '--:--' }}</small>
        </div>
    </div>
</template>

<script>
import api from '@/api';

export default {
    props: {
        src: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            position: 0,
            startTime: 0,
            durationSeconds: 0,
            loaded: false,
            playing: false,
            loading: false,
            failed: false,
            audioCtx: null,
            audioBuffer: null,
            audioSource: null,
            interval: null,
        }
    },
    computed: {
		percentComplete() {
			return parseInt(this.position / this.durationSeconds * 100);
		},
		progressStyle() {
			return { width: `${this.percentComplete}%` };
		},
        currentTime() {
            const position = Number.parseFloat(this.position);
            return position > this.durationSeconds ? this.durationSeconds : position;
        },
        iconShow() {
            if (this.loading)
                return 'gear';

            if (this.failed)
                return 'triangle-exclamation';

            if (!this.loaded)
                return 'download';

            if (this.playing)
                return 'pause';

            return 'play'
        }
	},
	watch: {
		playing(value) {
            if (this.audioBuffer) {
                if (value)
                    return this.play(this.position >= this.durationSeconds - 1 ? 0 : this.position);
                return this.pause()
            }
            
            this.load();
		},
	},
	methods: {
        load() {
            this.loading = true;
            this.audioCtx = new AudioContext();

            api.get(this.$props.src, { responseType: 'arraybuffer' })
                .then(res => {
                    if (res.status !== 200)
                        throw new Error(`HTTP error, status = ${res.status}`);
                    return res.data;
                })
                .then(buffer => this.audioCtx.decodeAudioData(buffer))
                .then(decodedData => {
                    this.audioBuffer = decodedData;
                    this.durationSeconds = Number.parseFloat(decodedData.duration);
                    this.loaded = true;
                    this.play();
                })
                .catch(e => {
                    this.failed = true;
                    console.log(e);
                })
                .finally(() => this.loading = false)
        },
        connect() {
            if (this.playing)
                this.pause();
                
            this.audioSource = this.audioCtx.createBufferSource();
            this.audioSource.buffer = this.audioBuffer;
            this.audioSource.connect(this.audioCtx.destination);
        },
        play(position) {
            clearInterval(this.interval);
            this.connect();
            this.position = typeof position === 'number' ? position : this.position || 0;
            this.startTime = this.audioCtx.currentTime - (this.position || 0);
            this.audioSource.start(this.audioCtx.currentTime, this.position);
            this.interval = setInterval(this.updatePosition, 1000);
            this.playing = true
        },
        pause() {
            if (this.audioSource) {
                clearInterval(this.interval);
                this.audioSource.stop(0);
                this.audioSource = null;
                this.position = this.calcTime();
                this.playing = false;
            }
        },
        calcTime() {
            return this.audioCtx.currentTime - this.startTime;
        },
        updatePosition() {
            this.position = this.calcTime();
            if (this.position >= this.durationSeconds) {
                this.playing = false;
                clearInterval(this.interval)
            }
        },
		convertTimeHHMMSS(val) {
			let hhmmss = new Date(Number.parseInt(val * 1000)).toISOString().substr(11, 8);

			return hhmmss.indexOf("00:") === 0 ? hhmmss.substr(3) : hhmmss;
		},
		seek(e) {
			if (!this.audioBuffer || this.durationSeconds === 0)
                return;

			const bounds = e.target.getBoundingClientRect();
			const seekPos = (e.clientX - bounds.left) / bounds.width;
            
            this.play(this.durationSeconds * seekPos);
		},
	},
}
</script>

<style lang="scss" scoped>
    .player-timeline {
        background-color: #DDD;
        height: 5px;
        flex: 1;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        
        .player-progress, .player-seeker {
            position: absolute;
            bottom: 0;
            left: 0;
            top: 0;
        }
        
        .player-progress {
            background-color: #317f98;
            z-index: 1;
            transition: width 200ms;
        }
        
        .player-seeker {
            cursor: pointer;
            width: 100%;
            z-index: 2;
        }
    }
</style>